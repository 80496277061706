import { Facebook, Instagram, Linkedin, LogoLight, Twitter } from '../../assets/svgs';
import { Container } from './styles';
import { Button } from '../../ui';
import { ModalContext } from '../../context';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Approutes } from '../../constants';

const Footer = () => {
	const { setModals } = useContext(ModalContext);

	return (
		<Container>
			<div className="top">
				<div className="description">
					<LogoLight />
					<p>Fueling Start-Ups & SMEs with Eager Interns and Graduates, to drive growth and empower Innovation.</p>
					<a href="https://app.descinder.com/signup">
						<Button>GET STARTED</Button>
					</a>
				</div>

				<div>
					<h5>Links</h5>

					<button onClick={() => document.querySelector('#us')?.scrollIntoView({ behavior: 'smooth' })}>Why Us</button>
					<button onClick={() => document.querySelector('#about')?.scrollIntoView({ behavior: 'smooth' })}>About Us</button>
					<button onClick={() => document.querySelector('#works')?.scrollIntoView({ behavior: 'smooth' })}>How It Works</button>
					<button onClick={() => document.querySelector('#faq')?.scrollIntoView({ behavior: 'smooth' })}>FAQs</button>
				</div>

				<div>
					<h5>Reach Out</h5>

					<a href="https://app.descinder.com/signup/as-intern">Intern | Graduate Profile</a>
					<a href="https://app.descinder.com/signup/as-startup">Start-Up Profile</a>
					<button onClick={() => setModals((prev) => ({ ...prev, showApplication: true, applicationType: 'start-up' }))}>
						Introductory Call
					</button>
					<button onClick={() => setModals((prev) => ({ ...prev, showConsult: true }))}>Consult Now</button>
				</div>

				<div className="contact">
					<h5>Contact</h5>

					<div>
						<a href="mailto:info@descinder.com">info@descinder.com</a>
						<a href="tel:+477-8556-7851">+4477-8556-7851</a>
					</div>
				</div>
			</div>
			<hr />
			<div className="bottom">
				<div className="links-con">
					<Link to={Approutes.docs.terms_of_use}>Terms of Use</Link>
					<Link to={Approutes.docs.privacy_policy}>Privacy Policy</Link>
					<Link to={Approutes.docs.code_of_conduct}>Code of Conduct</Link>
					<button onClick={() => document.querySelector('#faq')?.scrollIntoView({ behavior: 'smooth' })}>FAQ</button>
				</div>

				<p>© Descinder 2024. All Rights Reserved.</p>

				<div className="guide-con">
					<Link to={Approutes.docs.refund_policy}>Refund Policy</Link>
					<Link to={Approutes.docs.community_guidelines}>Community Guidelines</Link>

					<div className="socials-con">
						<a href="https://twitter.com/descinder" target="_blank" rel="noreferrer">
							<Twitter />
						</a>
						<a href="facebook.com/descinder" target="_blank" rel="noreferrer">
							<Facebook />
						</a>
						<a href="https://www.instagram.com/descinderhq/" target="_blank" rel="noreferrer">
							<Instagram />
						</a>
						<a href="https://www.linkedin.com/company/descinder11/" target="_blank" rel="noreferrer">
							<Linkedin />
						</a>
					</div>
				</div>
			</div>
		</Container>
	);
};

export default Footer;
