import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import posthog from 'posthog-js';

posthog.init('phc_ldpcgBfsKKTmV8oveQLjvuDKjOMNFZFOfhEKBCaXaY5', {
	api_host: 'https://eu.i.posthog.com',
	person_profiles: 'always', // or 'always' to create profiles for anonymous users as well
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);
